.pokemon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-info {
  display: flex;
}

.image, .info {
  width: 150px;
  height: 150px;
}

.image {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 6px #fff,
    inset 0px 0px 6px #fff;
}

.image img {
  max-width: 150px;
  max-height: 150px;
  position: absolute;
  bottom: 30%;
}

.icons {
  position: absolute;
  bottom: 12px;
}

.icons * {
  margin: 0 8px;
}

.icons *:hover {
  cursor: pointer;
  box-shadow: 0px 0px 3px #b5e9f6,
    inset 0px 0px 3px #b5e9f6;
}

.info {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.info div {
  border: 1.5px solid #ff8a8a;
  box-shadow: 0px 0px 3px #ff8a8a,
    inset 0px 0px 3px #ff8a8a;
}

.moves div {
  border: 1.5px solid #b5e9f6;
  box-shadow: 0px 0px 3px #b5e9f6, 
    inset 0px 0px 3px #b5e9f6;
}

.info div, .moves div {
  padding: 6px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 14px;
  height: 35px;
}

.moves {
  width: 300px;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 150px 150px;
  grid-gap: 8px 4px;
}