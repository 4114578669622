.randomizer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.title {
  font-size: 36px;
  text-shadow: 0 0 5px #00a9c7;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #00a9c7;
}

.team-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.team-options div {
  margin: 8px;
  width: 125px;
}

.team-options button {
  margin: 8px;
}

.team-options div select, .team-options div select:focus {
  background-color: transparent;
  color: white;
}

.team-options div select:hover {
  cursor: pointer;
}

.team-options div select option {
  background-color: rgb(14, 14, 14);
  color: white;
}

.btn-outline-primary {
  box-shadow: 0 0 10px #b5e9f6, inset 0 0 5px #b5e9f6;
  border-color: #b5e9f6;
}

.btn-outline-danger {
  box-shadow: 0 0 10px #ff8a8a, inset 0 0 5px #ff8a8a;
  border-color: #ff8a8a;
}

.btn:hover {
  color: white !important;
}

.card {
  width: 500px;
  height: 500px;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiCardHeader-root {
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px dashed black;
  z-index: 2;
}

@media only screen and (max-width: 700px) {
  .title {
    font-size: 5vw;
  }

  .card {
    width: 95vw;
    height: 75vh;
  }
}
